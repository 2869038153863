export default [
  {
    title: "الصفحة الرئيسية",
    route: "dashboard-home",
    icon: "mdi-home",
  },
  {
    title: "المكاتب",
    route: {
      name: "offices",
    },
    icon: "mdi-grid",
  },

  {
    title: "المناطق",
    route: {
      path: "/regions",
    },
    icon: "mdi-map",
  },
  {
    title: "الاعدادات",
    route: {
      path: "/posts",
    },
    icon: "mdi-cog",
  },
];
