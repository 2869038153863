import Vue from "vue";
import VueRouter from "vue-router";
// import { isElectron } from '../../utils'
import { isUserLoggedIn } from "@/libs/auth/utils";
Vue.use(VueRouter);
import dashboard from "./routes/dashboard";
const routes = [
  ...dashboard,
  { path: "/", redirect: { name: "dashboard-home" } },
  {
    name: "theme",
    path: "/theme",
    component: () => import("@/views/theme/Theme.vue"),
    meta: {
      title: "النمط",
    },
  },
  {
    name: "offices",
    path: "/offices",
    component: () => import("@/views/offices/Offices.vue"),
    meta: {
      title: "المكاتب",
    },
  },
  {
    name: "office",
    path: "/office/:id",
    component: () => import("@/views/offices/Office.vue"),
    meta: {
      title: "تفاصيل المكتب",
    },
  },
  {
    name: "regions",
    path: "/regions",
    component: () => import("@/views/regions/Regions.vue"),
    meta: {
      title: "المناطق",
    }
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      title: "تسجيل الدخول",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.name !== "login" && !isUserLoggedIn()) {
    return next({ name: "login" });
  }
  return next();
});
export default router;
